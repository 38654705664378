import React, { useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, ThemeContext } from 'grommet';
import { filter } from '../lib/nodash';
import { getProductPath } from '../lib/product';
import SmartLink from './SmartLink';
import TextTitle from './TextTitle';

import { StoryblokComponent } from 'gatsby-source-storyblok';

const BackdropMenu = ({ blok }) => {
  const { items, title, isSubmenu, textSize, level = 1, link, product } = blok;
  const submenus = filter({ component: 'Menu' }, items);
  const hasSubmenu = submenus.length > 0;
  const colCount = submenus.length > 1 ? 2 : 1;

  const theme = useContext(ThemeContext);
  const components = items.map((b) => {
    return (
      <StoryblokComponent
        blok={b}
        key={b._uid}
        parent={b}
        isSubmenu={hasSubmenu}
        textSize={textSize}
        level={level + 1}
      />
    );
  });

  const hasMenu =
    components.map((x) => x.props.blok.component).indexOf('Menu') > -1;

  const url = link
    ? product && product.item
      ? getProductPath(product.item)
      : link.linktype === 'email'
      ? `mailto:${link.email}`
      : link.cached_url
      ? link.cached_url
      : link.url
    : undefined;
  const rootedUrl = url
    ? (link && link.linktype === 'email') || link.url.indexOf('mailto:') > -1
      ? url
      : url[0] === '/'
      ? url
      : `/${url}`
    : null;

  return (
    <Box gap={isSubmenu && hasSubmenu ? 'small' : 'none'}>
      {title && (
        <TextTitle
          size={hasSubmenu ? '19px' : 'medium'}
          style={{
            marginBottom:
              level === 1
                ? theme.global.edgeSize.medium
                : theme.global.edgeSize.small,
          }}
        >
          <SmartLink to={rootedUrl} weight={500} plain>
            {title}
          </SmartLink>
        </TextTitle>
      )}
      <Grid
        className="outer"
        columns={{ count: colCount, size: 'auto' }}
        gap={!isSubmenu && hasMenu ? 'medium' : hasSubmenu ? 'xlarge' : 'none'}
      >
        {components}
      </Grid>
    </Box>
  );
};

BackdropMenu.propTypes = {
  blok: PropTypes.shape({
    items: PropTypes.array.isRequired,
    title: PropTypes.string,
    isSubmenu: PropTypes.bool,
    textSize: PropTypes.string,
    level: PropTypes.number,
    link: PropTypes.object,
    product: PropTypes.object,
  }),
};

export default memo(BackdropMenu);
